$().ready(function(){
	setupSlideMenu();
	bannerFunc();
	/** forms **/
	$("form").addClass("infield-active");
	$(".infield").inFieldLabels();
	$("form").each(function(){
		if (!$(this).hasClass("search")){
			$(this).validate();
		}
	});
	$(".page-content table").wrap("<div class=\"table-wrapper\"></div>");
	checkTables();
});
$(window).resize(function (){
	checkTables();
});
export function setupSlideMenu(){
/* mobile */
	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});
	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});
	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
	/* sidebar */
	$(".submenu").on("click",".expand", function(e){
		e.stopPropagation();
		$(this).closest("li").siblings().removeClass("expand-active");
		$(this).closest("li").toggleClass("expand-active");
	});
	$(".submenu li").each(function(index, element){
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			if ($el.hasClass("active")){
				$el.addClass("expand-active");
			}
			expander.click(function(e) {
				e.stopPropagation();
				$el.closest("li").siblings().removeClass("expand-active");
				$el.toggleClass("expand-active");
			});
		}
	});

}

export function topFunction() {
	$("html,body").animate({
		scrollTop: $("html,body").offset().top},
	"slow");
}

var rotateInterval;
export function bannerFunc(){
	var container = $(".banners-home");
	var banners = $(".banners-home .banner");
	var navItems = $(".banners-home .banner-nav-item");
	if (banners.length == 0){
		$(".banners-home").hide();
	} else if (banners.length == 1){
		$(".banners-home .banner").css({left:"0", opacity:"1"});
	} else {
		$(".banners-home .banner:first-child()").addClass("active on");
		container.append("<div class=\"previous\"><</div>");
		container.append("<div class=\"next\">></div>");
		banners.eq(1).addClass("right");
		navItems.hide();
		var swapBanner = function(reverse){
			if (container.data("animating")) return;
			container.data("animating",true);
			var newB, currentB = $(".banners-home .banner.active");
			if (reverse) {
				newB = currentB.prev();
				if (newB.length == 0) {
					newB = container.find(".banner:last-child()");
				}
			} else {
				newB = currentB.next();
				if (newB.length == 0) {
					newB = container.find(".banner:first-child()");
				}
			}
			newB.addClass((reverse?"left":"right") + " on");
			newB.height();
			currentB.addClass(reverse?"right":"left").removeClass("active").one("transitionend", function(){
				$(this).removeClass("on left right");
				container.data("animating",false);
			});
			newB.addClass("on active").removeClass("left right");
		};
		if (rotateInterval) {
			clearInterval(rotateInterval);
		}
		rotateInterval = setInterval(function(){swapBanner(false);},7000);
		container.find(".previous").on("click", function(){
			if(rotateInterval) {
				clearInterval(rotateInterval);
			}
			swapBanner(true);
			rotateInterval = setInterval(function(){swapBanner(false);},7000);
		});
		container.find(".next").on("click", function(){
			if(rotateInterval) {
				clearInterval(rotateInterval);
			}
			swapBanner(false);
			rotateInterval = setInterval(function(){swapBanner(false);},7000);
		});
	}
}

export function checkTables(){
	$(".table-wrapper").each(function(){
		var wrapW = $(this).width();
		var tableW = $(this).find("table").width();
		if (tableW > wrapW){
			$(this).addClass("active");
		} else {
			$(this).removeClass("active");
		}
	});
}

$("#status").on("change", function(){
	var options = $("div.options");
	var status = $(this).val();
	options.html("");
	if (status == "disposed"){
		options.html("<div class=\"half\"><p><label for=\"datedisp\" class=\"infield\">Date Disposed*</label>"+
                     "<input type=\"text\" name=\"Date Disposed\" id=\"datedisp\" class=\"required\" required></p>"+
                     "<p><label for=\"dispreason\" class=\"infield\">Disposed Reason*</label>"+
                     "<input type=\"text\" name=\"disposed reason\" id=\"dispreason\" class=\"required\" required></p></div>");
	} else {
		options.html("<p><label for=\"date"+status+"\" class=\"infield\">Date "+status+"*</label>"+
                    "<input type=\"text\" name=\"Date "+status+"\" id=\"date"+status+"\" class=\"required\" required></p>");
	}
	$(".options .infield").inFieldLabels();
	$(".statustype").html(status);
});
$("#transfer").on("change", function(){
	var mode = $(this).val();
	$("span.mode").text(mode);
	$("label."+ mode).append("*");
	$("input."+ mode).addClass("required");

	if (mode == "vendor"){
		$("span.opposite").text("Purchaser");
		$("label.purchaser").each(function(){
			var name = $(this).next("input").attr("name").split(" ");
			$(this).text(name.pop());
		});
		$("input.purchaser").removeClass("required");
	} else { //purchaser
		$("label.vendor").each(function(){
			var name = $(this).next("input").attr("name").split(" ");
			$(this).text(name.pop());
		});
		$("input.vendor").removeClass("required");
		$("span.opposite").text("Vendor");
	}
	$("form.transfer-notification").validate();
});



//$('#country').on('change', function() {
//   if ( this.value == 'USA')
//     $("#states").show();     
//   else
//     $("#states").hide();
// }).trigger("change"); // notice this line